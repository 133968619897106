<template>
    <b-card title="Generate Promocode">
        <b-col cols="12" style="margin-bottom:15px">
            <label>Campaign <span style="color:red">(*)</span></label>
            <b-form-select
                v-model="form.campaign"
                placeholder="----- Select Campaign -----"
                :options="campaigns">
            </b-form-select>
        </b-col>

        <b-col cols="12" style="margin-bottom:15px">
            <label>Status <span style="color:red">(*)</span></label>
            <b-form-select
                v-model="form.status"
                placeholder="----- Select Status -----"
                :options="statusChoices"
            >
            </b-form-select>
        </b-col>

        <b-col cols="12" style="margin-bottom:15px">
            <label>Total <span style="color:red">(*)</span></label>
            <b-form-input v-model="form.total" placeholder="Total Promocode To Generate (1-999)"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-1">
            <div class="d-flex justify-content center w-100">
                <b-button :disabled="form.campaign==null" variant="success" @click="doGeneratePromoCode">Generate</b-button>
            </div>
        </b-col>
    </b-card>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { sleep }from '@/utils/utils.js'
export default {
    data() {
        return {
            form: {
                campaign: null,
                total: 1,
                status: 'Active',
            },
            statusChoices: [
                { value: 'Active', text: 'Active' },
                { value: 'Inactive', text: 'Inactive' },
            ]
        }
    },
    mounted() {
        this.getCampaigns();
    },
    methods: {
        ...mapActions({
            'getCampaigns': 'campaign/getCampaigns',
            'generatePromoCode': 'pcode/generatePromoCode'
        }),
        doGeneratePromoCode() {
            this.generatePromoCode(this.form).then(async (response) => {
                this.$bvToast.toast(`Promo Code Created Successfully`, {
                    title: "Success",
                    variant: "success",
                    solid: true,
                });
                await sleep(1500)
                this.$router.go(-1);
            }).catch((err) => {
                if(err.request.status === 400){
                    this.$bvToast.toast(`${err.response.data.errors[0].message}`, {
                    title: "Failed",
                    variant: "danger",
                    solid: true,
                    });
                }else{
                this.$bvToast.toast(`You have failed to generate a Promo Code`, {
                    title: "Fail",
                    variant: "danger",
                    solid: true,
                });}
            })
        }
    },
    computed: {
        ...mapState({
            campaigns: (state) => {
                return state.campaign.campaigns.map(campaign => {
                    return { value: campaign._id, text: campaign.name };
                })
            },
        })
    }
}
</script>